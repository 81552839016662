define("fx/components/monitoring/risk-results-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ReportTable
    ...attributes
    as |table|
  >
    <table.Caption>
      {{yield (hash
        Caption=(component "blank")
      )}}
    </table.Caption>
  
    <table.Content>
      <thead>
        <tr>
          <th rowspan="2" colspan="2"></th>
          {{#each @currencies as |currency|}}
            <th rowspan="2">{{currency.isoCode}}</th>
          {{/each}}
          <th rowspan="2">{{t "total"}}</th>
          <th colspan="2">{{t "monitoring.results.budget-gap"}}</th>
        </tr>
        <tr>
          <th>{{t "without-hedging"}}</th>
          <th>{{t "with-hedging"}}</th>
        </tr>
      </thead>
  
      {{yield (hash
        Content=(component "blank")
      )}}
    </table.Content>
  </ReportTable>
  
  */
  {
    "id": "t4hz3fkU",
    "block": "[[[8,[39,0],[[17,1]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"Caption\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[18,5,[[28,[37,2],null,[[\"Caption\"],[[50,\"blank\",0,null,null]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,2,[\"Content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[14,\"rowspan\",\"2\"],[14,\"colspan\",\"2\"],[12],[13],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,3]],null]],null],null,[[[1,\"          \"],[10,\"th\"],[14,\"rowspan\",\"2\"],[12],[1,[30,4,[\"isoCode\"]]],[13],[1,\"\\n\"]],[4]],null],[1,\"        \"],[10,\"th\"],[14,\"rowspan\",\"2\"],[12],[1,[28,[35,6],[\"total\"],null]],[13],[1,\"\\n        \"],[10,\"th\"],[14,\"colspan\",\"2\"],[12],[1,[28,[35,6],[\"monitoring.results.budget-gap\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[12],[1,[28,[35,6],[\"without-hedging\"],null]],[13],[1,\"\\n        \"],[10,\"th\"],[12],[1,[28,[35,6],[\"with-hedging\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[18,5,[[28,[37,2],null,[[\"Content\"],[[50,\"blank\",0,null,null]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"&attrs\",\"table\",\"@currencies\",\"currency\",\"&default\"],false,[\"report-table\",\"yield\",\"hash\",\"component\",\"each\",\"-track-array\",\"t\"]]",
    "moduleName": "fx/components/monitoring/risk-results-table.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});