define("fx/templates/admin/roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o4BZ+ZS+",
    "block": "[[[10,\"main\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[12],[1,[28,[35,1],[\"admin.roles.title\"],null]],[13],[1,\"\\n\\n    \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"content\",\"t\",\"component\",\"-outlet\"]]",
    "moduleName": "fx/templates/admin/roles.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});