define("fx/templates/budgets/budget/budget-periods/budget-period/hedging/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MFr0e2Dq",
    "block": "[[[10,0],[14,0,\"heading\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,[28,[35,0],[\"hedging.title\"],null]],[13],[1,\"\\n\\n\"],[41,[51,[30,0,[\"noCategoriesLeft\"]]],[[[1,\"    \"],[8,[39,2],[[24,0,\"button\"]],[[\"@route\"],[\"budgets.budget.budget-periods.budget-period.hedging.new\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,0],[\"hedging.new.title\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[10,\"ul\"],[14,0,\"index-list\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"analyses\"]]],null]],null],null,[[[1,\"    \"],[8,[39,5],null,[[\"@analysis\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@target\"],[\"sidebar\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"hedging.description\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"analysis\"],false,[\"t\",\"unless\",\"link-to\",\"each\",\"-track-array\",\"hedging/analysis-list-item\",\"portal\",\"sidebar\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/hedging/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});