define("fx/templates/budgets/budget/budget-periods/budget-period/hedging/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s1/EBH2Y",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,0],[\"hedging.new.title\"],null]],[13],[1,\"\\n\\n\"],[11,\"form\"],[4,[38,1],[\"submit\",[30,0,[\"analysisCreate\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"form__row\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,\"for\",\"analysis__category\"],[14,0,\"label--required\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[\"hedging.analyses.category\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@selected\",\"@options\",\"@searchField\",\"@placeholder\",\"@onChange\",\"@id\"],[[30,0,[\"selectedAnalysisCategory\"]],[30,0,[\"categoryOptions\"]],\"label\",[28,[37,0],[\"select-placeholder\"],null],[28,[37,3],[[30,0,[\"changeSelectValue\"]],\"category\",\"id\"],null],\"analysis__category\"]],null],[1,\"\\n\\n    \"],[8,[39,4],null,[[\"@errors\",\"@attribute\"],[[30,0,[\"errors\"]],\"category\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,5],null,[[\"@analysis\"],[[30,0,[\"analysis\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@target\"],[\"sidebar\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"hedging.description\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,8],null,[[\"@save\",\"@hideText\"],[[30,0,[\"analysisCreate\"]],true]],null]],[],false,[\"t\",\"on\",\"soilable-power-select\",\"fn\",\"field-errors\",\"hedging/analysis-changer\",\"portal\",\"sidebar\",\"flow-bar\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/hedging/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});