define("fx/components/reports/sensitivity-impact-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <th>
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{@header}}
      {{/if}}
    </th>
  
    <Reports::CurrencyCell
      @value={{@total}}
    />
  
    {{#each @dataset as |value|}}
      <Reports::CurrencyCell
        @value={{value.impact}}
      />
    {{/each}}
  </tr>
  */
  {
    "id": "q6lb37dV",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[10,\"th\"],[12],[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"      \"],[18,6,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,2]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@value\"],[[30,3]]],null],[1,\"\\n\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,4]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@value\"],[[30,5,[\"impact\"]]]],null],[1,\"\\n\"]],[5]],null],[13]],[\"&attrs\",\"@header\",\"@total\",\"@dataset\",\"value\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"reports/currency-cell\",\"each\",\"-track-array\"]]",
    "moduleName": "fx/components/reports/sensitivity-impact-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});