define("fx/templates/budgets/budget/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BXCTk566",
    "block": "[[[10,\"main\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@root\"],[\"budgets\"]],null],[1,\"\\n\\n    \"],[10,\"h1\"],[12],[1,[28,[35,2],[\"budget.edit.title\"],null]],[13],[1,\"\\n\\n    \"],[11,\"form\"],[4,[38,3],[\"submit\",[30,0,[\"budgetUpdate\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"form__row\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,\"for\",\"budget__primary-currency\"],[12],[1,\"\\n          \"],[1,[28,[35,2],[\"budgets.primary-currency\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"input\"],[14,1,\"budget__primary-currency\"],[14,3,\"budget__primary-currency\"],[15,2,[30,0,[\"budget\",\"primaryCurrency\",\"isoCode\"]]],[15,\"disabled\",true],[14,4,\"text\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[39,4],null,[[\"@budget\",\"@products\",\"@errors\",\"@initialStartsOn\"],[[30,0,[\"budget\"]],[30,0,[\"products\"]],[30,0,[\"errors\"]],[30,0,[\"initialStartsOn\"]]]],null],[1,\"\\n\\n      \"],[10,0],[14,0,\"form__actions\"],[12],[1,\"\\n        \"],[8,[39,5],null,[[\"@type\"],[\"submit\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,2],[\"save\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"content\",\"breadcrumb\",\"t\",\"on\",\"budgets/base-form\",\"data-button\"]]",
    "moduleName": "fx/templates/budgets/budget/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});