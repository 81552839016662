define("fx/templates/budgets/budget/budget-periods/budget-period/hedging/analysis/forward-contract-hedging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qxknOh1J",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,0],[\"hedging.analysis.forward-contract-hedging.title\"],null]],[13],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@forwardContractHedging\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@forwardContractHedging\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,null,null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@target\"],[\"sidebar\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@analysis\",\"@refetchQueries\"],[[30,0,[\"analysis\"]],[28,[37,7],[\"ForwardContractHedging\"],null]]],null],[1,\"\\n\\n    \"],[1,[28,[35,0],[\"hedging.analysis.forward-contract-hedging.description\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"t\",\"hedging/forward-contract-hedging-table\",\"hedging/forward-contract-hedging-credit-table\",\"flow-bar\",\"portal\",\"sidebar\",\"hedging/analysis-updater\",\"array\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/hedging/analysis/forward-contract-hedging.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});