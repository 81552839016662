define("fx/templates/budgets/budget/budget-periods/budget-period/data/financial-flows/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bc1UXmcj",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,0],[\"financial-flows.title\"],null]],[13],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@state\"],[[30,0,[\"state\"]]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"warning\"],[12],[1,\"\\n  \"],[10,2],[12],[1,[28,[35,0],[\"financial-flows.debt-warning\"],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"form\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"financialFlowsByCurrency\"]]],null]],null],null,[[[1,\"    \"],[8,[39,4],null,[[\"@financialFlows\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@save\",\"@nextStep\"],[[30,0,[\"financialFlowsUpdate\"]],\"budgets.budget.budget-periods.budget-period.data.index\"]],null],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@target\"],[\"sidebar\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"financial-flows.description\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"financialFlows\"],false,[\"t\",\"data/step-errors\",\"each\",\"-track-array\",\"data/financial-flows-table\",\"flow-bar\",\"portal\",\"sidebar\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/data/financial-flows/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});