define("fx/components/hedging/risk-tolerance-currency-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @dataset}}
    <tr ...attributes>
      <th>
        {{#if (has-block)}}
          {{yield}}
        {{else}}
          {{@header}}
  
          {{#if @subheader}}
            <span class="hint">{{@subheader}}</span>
          {{/if}}
        {{/if}}
  
      </th>
  
      {{#each @dataset.currencies as |row|}}
        <Reports::CurrencyCell
          @value={{row.value}}
        />
      {{/each}}
  
      <Reports::CurrencyCell
        @value={{@dataset.total.value}}
      />
    </tr>
  {{/if}}
  */
  {
    "id": "4HViqWMX",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,\"tr\"],[17,2],[12],[1,\"\\n    \"],[10,\"th\"],[12],[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"        \"],[18,6,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,3]],[1,\"\\n\\n\"],[41,[30,4],[[[1,\"          \"],[10,1],[14,0,\"hint\"],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"currencies\"]]],null]],null],null,[[[1,\"      \"],[8,[39,5],null,[[\"@value\"],[[30,5,[\"value\"]]]],null],[1,\"\\n\"]],[5]],null],[1,\"\\n    \"],[8,[39,5],null,[[\"@value\"],[[30,1,[\"total\",\"value\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@dataset\",\"&attrs\",\"@header\",\"@subheader\",\"row\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"each\",\"-track-array\",\"reports/currency-cell\"]]",
    "moduleName": "fx/components/hedging/risk-tolerance-currency-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});