define("fx/schema", ["exports", "graphql-tools", "graphql", "fx/gql/schema.graphql", "numeral"], function (_exports, _graphqlTools, _graphql, _schema, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var resolvers = {
    Percent: new _graphql.GraphQLScalarType({
      name: 'Percent',
      serialize: function serialize(parsed) {
        var _numeral$value;

        return (_numeral$value = (0, _numeral.default)(parsed).value()) === null || _numeral$value === void 0 ? void 0 : _numeral$value.toString();
      },
      parseValue: function parseValue(raw) {
        return parseFloat(raw);
      }
    }),
    Decimal: new _graphql.GraphQLScalarType({
      name: 'Decimal',
      serialize: function serialize(parsed) {
        var _numeral$value2;

        return (_numeral$value2 = (0, _numeral.default)(parsed).value()) === null || _numeral$value2 === void 0 ? void 0 : _numeral$value2.toString();
      },
      parseValue: function parseValue(raw) {
        return parseFloat(raw);
      }
    })
  };

  var _default = (0, _graphqlTools.makeExecutableSchema)({
    typeDefs: _schema.default,
    resolvers: resolvers,
    requireResolversForResolveType: false
  });

  _exports.default = _default;
});