define("fx/components/hedging/requisite-coverage-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <Table::DateHeader
      @date={{@month}}
    />
  
    <Reports::CurrencyCell
      @value={{@dataset.grossExposure}}
    />
  
    {{#each @dataset.hedgingMargins as |hedgingMargin|}}
      <Hedging::RequisiteCoverageHedgingMargin
        @hedgingMargin={{hedgingMargin}}
        @field={{"value"}}
      />
    {{/each}}
  
    {{yield}}
  </tr>
  */
  {
    "id": "R0uaLSI8",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@date\"],[[30,2]]],null],[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@value\"],[[30,3,[\"grossExposure\"]]]],null],[1,\"\\n\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,3,[\"hedgingMargins\"]]],null]],null],null,[[[1,\"    \"],[8,[39,4],null,[[\"@hedgingMargin\",\"@field\"],[[30,4],\"value\"]],null],[1,\"\\n\"]],[4]],null],[1,\"\\n  \"],[18,5,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@month\",\"@dataset\",\"hedgingMargin\",\"&default\"],false,[\"table/date-header\",\"reports/currency-cell\",\"each\",\"-track-array\",\"hedging/requisite-coverage-hedging-margin\",\"yield\"]]",
    "moduleName": "fx/components/hedging/requisite-coverage-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});