define("fx/templates/plans/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NpnZ05lF",
    "block": "[[[10,\"main\"],[14,0,\"no-bg\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"content--small\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[1,[28,[35,1],[\"plans.index.title\"],null]],[13],[1,\"\\n    \"],[10,2],[14,0,\"text-center large-text content--small\"],[12],[1,\"\\n\"],[41,[30,0,[\"baseOffer\"]],[[[1,\"        \"],[1,[28,[35,1],[\"plans.index.base-offer\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,1],[\"plans.index.savings\"],[[\"savings\",\"htmlSafe\"],[[30,0,[\"tierSavings\"]],true]]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"space-between\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"        \"],[8,[39,5],null,[[\"@plan\",\"@subscribe\"],[[30,1],[30,0,[\"checkoutSessionCreate\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"plan\"],false,[\"content\",\"t\",\"if\",\"each\",\"-track-array\",\"plan-card\"]]",
    "moduleName": "fx/templates/plans/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});