define("fx/components/loading-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="loading-indicator">
    <span class="loading-indicator__label">
      {{#if (has-block)}}
        {{yield}}
      {{else if @message}}
        {{@message}}
      {{else}}
        {{t "loading.generic"}}
      {{/if}}
    </span>
    <svg xmlns="http://www.w3.org/2000/svg" width="64px" height="64px" viewBox="0 0 128 128">
      <g>
        <path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" fill="currentColor" fill-opacity="1"/>
        <animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="800ms" repeatCount="indefinite"></animateTransform>
      </g>
    </svg>
  </div>
  */
  {
    "id": "Rg1KQX4E",
    "block": "[[[10,0],[14,0,\"loading-indicator\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"loading-indicator__label\"],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"      \"],[18,2,null],[1,\"\\n\"]],[]],[[[41,[30,1],[[[1,\"      \"],[1,[30,1]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,3],[\"loading.generic\"],null]],[1,\"\\n    \"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"64px\"],[14,\"height\",\"64px\"],[14,\"viewBox\",\"0 0 128 128\"],[12],[1,\"\\n    \"],[10,\"g\"],[12],[1,\"\\n      \"],[10,\"path\"],[14,\"d\",\"M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z\"],[14,\"fill\",\"currentColor\"],[14,\"fill-opacity\",\"1\"],[12],[13],[1,\"\\n      \"],[10,\"animateTransform\"],[14,\"attributeName\",\"transform\"],[14,\"from\",\"0 64 64\"],[14,\"to\",\"360 64 64\"],[14,\"dur\",\"800ms\"],[14,\"repeatCount\",\"indefinite\"],[14,4,\"rotate\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@message\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"t\"]]",
    "moduleName": "fx/components/loading-indicator.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});