define("fx/components/hedging/overview-rates-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <th>
      {{@dataset.currency.isoCode}}
    </th>
  
    <Reports::NumericCell
      @value={{@dataset.rate}}
      @maximumFractionDigits={{6}}
    />
  
    <Reports::NumericCell
      @value={{@dataset.simulatedRate}}
      @maximumFractionDigits={{6}}
    />
  
    {{yield}}
  </tr>
  */
  {
    "id": "Q1Hz1gMv",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[10,\"th\"],[12],[1,\"\\n    \"],[1,[30,2,[\"currency\",\"isoCode\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@value\",\"@maximumFractionDigits\"],[[30,2,[\"rate\"]],6]],null],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@value\",\"@maximumFractionDigits\"],[[30,2,[\"simulatedRate\"]],6]],null],[1,\"\\n\\n  \"],[18,3,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@dataset\",\"&default\"],false,[\"reports/numeric-cell\",\"yield\"]]",
    "moduleName": "fx/components/hedging/overview-rates-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});