define("fx/templates/budgets/budget/budget-periods/budget-period/data/monthly-expenses/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0aV3iN8G",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,0],[\"monthly-expenses.title\"],null]],[13],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@state\"],[[30,0,[\"state\"]]]],null],[1,\"\\n\\n\"],[10,\"form\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"form__row\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@value\",\"@target\",\"@field\",\"@errors\",\"@options\",\"@name\"],[[30,0,[\"budgetPeriod\",\"useMonthlySalesForCosts\"]],[30,0,[\"budgetPeriod\"]],\"useMonthlySalesForCosts\",[30,0,[\"errors\"]],[30,0,[\"monthlyExpensesUsageOptions\"]],\"useMonthlySalesForCosts\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[51,[30,0,[\"budgetPeriod\",\"useMonthlySalesForCosts\"]]],[[[1,\"    \"],[8,[39,4],[[24,0,\"form__table\"]],[[\"@table\",\"@valueFormat\",\"@headerFormat\",\"@displayColumnTotals\",\"@errors\"],[[30,0,[\"monthlyExpenses\"]],\"percent\",\"month\",true,[30,0,[\"errors\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"Caption\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"table-export__title\"],[12],[1,\"\\n          \"],[1,[28,[35,0],[\"monthly-expenses.table.title\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,1],[14,0,\"subtitle\"],[12],[1,[28,[35,0],[\"monthly-expenses.table.subtitle\"],[[\"htmlSafe\"],[true]]]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\\n    \"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,0],[\"monthly-expenses.table.notes\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@save\"],[[30,0,[\"save\"]]]],null],[1,\"\\n\\n\"],[8,[39,7],null,[[\"@target\"],[\"sidebar\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,8],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"monthly-expenses.description\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"Table\"],false,[\"t\",\"data/step-errors\",\"radio/group\",\"unless\",\"data-table\",\"table/notes\",\"flow-bar\",\"portal\",\"sidebar\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/data/monthly-expenses/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});