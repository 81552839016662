define("fx/templates/products/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rARMC55A",
    "block": "[[[10,\"main\"],[14,0,\"no-bg\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"content--x-small\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[12],[1,[28,[35,1],[\"products.edit\"],null]],[13],[1,\"\\n\\n    \"],[11,\"form\"],[24,0,\"box\"],[4,[38,2],[\"submit\",[30,0,[\"productUpdate\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"form__row\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,\"for\",\"product__name\"],[12],[1,\"\\n          \"],[1,[28,[35,1],[\"product.name\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[11,\"input\"],[24,1,\"product__name\"],[24,3,\"product__name\"],[16,2,[30,0,[\"product\",\"name\"]]],[24,4,\"text\"],[4,[38,2],[\"change\",[28,[37,3],[[30,0,[\"changeProductValue\"]],\"name\"],null]],null],[12],[13],[1,\"\\n        \"],[8,[39,4],null,[[\"@errors\",\"@attribute\"],[[30,0,[\"errors\"]],\"name\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"box__actions\"],[12],[1,\"\\n        \"],[8,[39,5],null,[[\"@type\"],[\"submit\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,1],[\"save\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"content\",\"t\",\"on\",\"fn\",\"field-errors\",\"data-button\"]]",
    "moduleName": "fx/templates/products/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});