define("fx/templates/admin/roles/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vQOvI0Mr",
    "block": "[[[10,\"h2\"],[12],[1,[30,0,[\"roleEmail\"]]],[13],[1,\"\\n\\n\"],[11,\"form\"],[4,[38,0],[\"submit\",[30,0,[\"roleUpdate\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"form__row\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,\"for\",\"role__role\"],[14,0,\"label--required\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"admin.users.role\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@selected\",\"@options\",\"@searchField\",\"@onChange\"],[[30,0,[\"selectedRole\"]],[30,0,[\"roleOptions\"]],\"label\",[28,[37,3],[[30,0,[\"changeRoleValue\"]],\"role\"],null]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,1,[\"label\"]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n    \"],[8,[39,4],null,[[\"@errors\",\"@attribute\"],[[30,0,[\"errors\"]],\"name\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"form__actions form__actions--items-center form__actions--gap-4\"],[12],[1,\"\\n    \"],[8,[39,5],null,[[\"@route\"],[\"admin.users\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,1],[\"cancel\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[10,\"button\"],[14,4,\"submit\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"save\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[46,[28,[37,7],null,null],null,null,null]],[\"role\"],false,[\"on\",\"t\",\"power-select\",\"fn\",\"field-errors\",\"link-to\",\"component\",\"-outlet\"]]",
    "moduleName": "fx/templates/admin/roles/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});