define("fx/templates/budgets/budget/budget-periods/budget-period/forward-transactions/for-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2yQinmYB",
    "block": "[[[10,0],[14,0,\"heading heading--start\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,[30,0,[\"title\"]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"form__row no-margin--bottom\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@selected\",\"@options\",\"@placeholder\",\"@searchEnabled\",\"@searchField\",\"@onChange\"],[[30,0,[\"currency\"]],[30,0,[\"currencies\"]],[28,[37,1],[\"currencies.select_new\"],null],true,\"isoCode\",[30,0,[\"selectNewCurrency\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,1,[\"isoCode\"]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n    \"],[8,[39,2],null,[[\"@errors\",\"@attribute\"],[[30,0,[\"errors\"]],\"isoCode\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[46,[28,[37,4],null,null],null,null,null]],[\"currency\"],false,[\"power-select\",\"t\",\"field-errors\",\"component\",\"-outlet\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/forward-transactions/for-currency.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});