define("fx/components/monitoring/unrealized-product-results-percent-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <th>
      {{@header}}
    </th>
  
    {{#each @dataset as |product|}}
      <Reports::PercentCell
        @value={{product.percent}}
      />
    {{/each}}
  
    <Reports::PercentCell
      @value={{@total}}
    />
  
    {{yield}}
  </tr>
  */
  {
    "id": "Uyt45bqz",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[10,\"th\"],[12],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,3]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@value\"],[[30,4,[\"percent\"]]]],null],[1,\"\\n\"]],[4]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@value\"],[[30,5]]],null],[1,\"\\n\\n  \"],[18,6,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@header\",\"@dataset\",\"product\",\"@total\",\"&default\"],false,[\"each\",\"-track-array\",\"reports/percent-cell\",\"yield\"]]",
    "moduleName": "fx/components/monitoring/unrealized-product-results-percent-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});