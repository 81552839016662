define("fx/templates/coming-soon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hi5HUBIX",
    "block": "[[[10,\"main\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"inner\"],[12],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[1,[28,[35,0],[\"coming-soon\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"t\"]]",
    "moduleName": "fx/templates/coming-soon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});