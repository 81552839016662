define("fx/components/hedging/forward-contract-hedging-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <Table::DateHeader
      @date={{@month}}
      @header={{@header}}
    />
  
    {{#each @dataset as |data|}}
      <Reports::CurrencyCell
        @value={{data.hedgingAmount.value}}
      />
  
      <Reports::PercentCell
        @value={{data.hedgingRatio.value}}
      />
    {{/each}}
  
    <Reports::CurrencyCell
      @value={{@total.value}}
    />
  
    {{yield}}
  </tr>
  */
  {
    "id": "Ob2iu6lF",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@date\",\"@header\"],[[30,2],[30,3]]],null],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,4]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@value\"],[[30,5,[\"hedgingAmount\",\"value\"]]]],null],[1,\"\\n\\n    \"],[8,[39,4],null,[[\"@value\"],[[30,5,[\"hedgingRatio\",\"value\"]]]],null],[1,\"\\n\"]],[5]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@value\"],[[30,6,[\"value\"]]]],null],[1,\"\\n\\n  \"],[18,7,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@month\",\"@header\",\"@dataset\",\"data\",\"@total\",\"&default\"],false,[\"table/date-header\",\"each\",\"-track-array\",\"reports/currency-cell\",\"reports/percent-cell\",\"yield\"]]",
    "moduleName": "fx/components/hedging/forward-contract-hedging-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});