define("fx/components/reports/financial-flow-totals-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    {{#if @month}}
      <Table::DateHeader
        @date={{@month}}
      />
    {{else}}
      <th>
        {{@header}}
      </th>
    {{/if}}
  
    <Reports::CurrencyCell
      @value={{@dataset.sales}}
    />
  
    <Reports::CurrencyCell
      @value={{@dataset.purchases}}
    />
  
    <Reports::CurrencyCell
      @value={{@dataset.netPosition}}
    />
  
    <Reports::CurrencyCell
      @value={{@dataset.availableForHedging}}
    />
  
    <td>
      <Reports::FinancialFlowCategory
        @type={{@dataset.monthlyTransactions}}
        @currency={{@currency}}
        @side="left"
      />
    </td>
  </tr>
  
  {{yield}}
  */
  {
    "id": "2LivNFgS",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[8,[39,1],null,[[\"@date\"],[[30,2]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"th\"],[12],[1,\"\\n      \"],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[8,[39,2],null,[[\"@value\"],[[30,4,[\"sales\"]]]],null],[1,\"\\n\\n  \"],[8,[39,2],null,[[\"@value\"],[[30,4,[\"purchases\"]]]],null],[1,\"\\n\\n  \"],[8,[39,2],null,[[\"@value\"],[[30,4,[\"netPosition\"]]]],null],[1,\"\\n\\n  \"],[8,[39,2],null,[[\"@value\"],[[30,4,[\"availableForHedging\"]]]],null],[1,\"\\n\\n  \"],[10,\"td\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@type\",\"@currency\",\"@side\"],[[30,4,[\"monthlyTransactions\"]],[30,5],\"left\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[18,6,null]],[\"&attrs\",\"@month\",\"@header\",\"@dataset\",\"@currency\",\"&default\"],false,[\"if\",\"table/date-header\",\"reports/currency-cell\",\"reports/financial-flow-category\",\"yield\"]]",
    "moduleName": "fx/components/reports/financial-flow-totals-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});