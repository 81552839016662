define("fx/components/product/row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li class="budget-products__row">
    {{@product.name}}
  
    {{yield}}
  
    <button
      class="budget-products__remove"
      type="button"
      {{on "click" this.remove}}
    >
      {{t "remove"}}
    </button>
  </li>
  */
  {
    "id": "pvUuOQ/P",
    "block": "[[[10,\"li\"],[14,0,\"budget-products__row\"],[12],[1,\"\\n  \"],[1,[30,1,[\"name\"]]],[1,\"\\n\\n  \"],[18,2,null],[1,\"\\n\\n  \"],[11,\"button\"],[24,0,\"budget-products__remove\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"remove\"]]],null],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"remove\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@product\",\"&default\"],false,[\"yield\",\"on\",\"t\"]]",
    "moduleName": "fx/components/product/row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});