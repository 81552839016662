define("fx/templates/budgets/budget/budget-periods/budget-period/monitoring/alerts-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "suPcL89k",
    "block": "[[[10,\"h1\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"monitoring.alerts-table.title\"],null]],[1,\"\\n  \"],[8,[39,1],null,[[\"@budgetPeriod\",\"@analysis\"],[[30,0,[\"budgetPeriod\"]],[30,0,[\"analysis\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,2],null,[[\"@refetchQueries\"],[[28,[37,3],[\"AlertsTable\",\"MovementLimit\",\"HedgingRecalibrationTable\"],null]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@alertsTable\"],[[30,0,[\"alertsTable\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@movementLimit\"],[[30,0,[\"movementLimit\"]]]],null],[1,\"\\n\\n\\n\"],[8,[39,6],null,[[\"@currencies\",\"@analysis\",\"@recalibrationCurrencyId\",\"@nonPrimaryCurrencies\",\"@recalibrationRate\",\"@setRecalibrationRate\",\"@applyRecalibrationRate\",\"@hedgingRecalibrationTable\",\"@openForwardTransactionModal\"],[[30,0,[\"currencies\"]],[30,0,[\"analysis\"]],[30,0,[\"recalibrationCurrencyId\"]],[30,0,[\"nonPrimaryCurrencies\"]],[30,0,[\"budgetPeriod\",\"recalibrationRate\"]],[30,0,[\"setRecalibrationRate\"]],[30,0,[\"applyRecalibrationRate\"]],[30,0,[\"hedgingRecalibrationTable\"]],[30,0,[\"openForwardTransactionModal\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"isShowingForwardTransactionModal\"]],[[[1,\"  \"],[8,[39,8],null,[[\"@onClose\",\"@forwardTransaction\",\"@action\",\"@refetchQueries\"],[[30,0,[\"closeForwardTransactionModal\"]],[30,0,[\"forwardTransaction\"]],\"new\",[30,0,[\"refetchQueriesForTransactionModal\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[46,[28,[37,10],null,null],null,null,null],[1,\"\\n\\n\"],[8,[39,11],null,null,null],[1,\"\\n\"]],[],false,[\"t\",\"monitoring/heading\",\"monitoring/currency-rate-override-table\",\"array\",\"monitoring/alerts-table\",\"monitoring/movement-limit-table\",\"monitoring/hedging-recalibration-table\",\"if\",\"forward-transaction/modal-dialog\",\"component\",\"-outlet\",\"flow-bar\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/monitoring/alerts-table.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});