define("fx/components/budgets/budget-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li
    class="budget-card"
    ...attributes
  >
    <LinkTo
      @route="budgets.budget.budget-periods"
      @model={{@budget.id}}
      class="budget-card__header"
    >
      <h2 class="budget-card__name">{{@budget.name}}</h2>
  
      <div class="budget-card__actions">
        <Budgets::DeleteButton
          @budget={{@budget}}
        />
      </div>
    </LinkTo>
    <div class="budget-card__content">
      <LinkTo
        @route="budgets.budget.edit"
        @model={{@budget.id}}
        class="budget-card__link"
      >
        {{t "budget.edit.title"}}
      </LinkTo>
      <LinkTo
        @route="budgets.budget.budget-periods"
        @model={{@budget.id}}
        class="budget-card__link"
      >
        {{t "budget-periods.title"}}
      </LinkTo>
    </div>
  </li>
  */
  {
    "id": "Q6SkeOTN",
    "block": "[[[11,\"li\"],[24,0,\"budget-card\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"budget-card__header\"]],[[\"@route\",\"@model\"],[\"budgets.budget.budget-periods\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"h2\"],[14,0,\"budget-card__name\"],[12],[1,[30,2,[\"name\"]]],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"budget-card__actions\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@budget\"],[[30,2]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[10,0],[14,0,\"budget-card__content\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"budget-card__link\"]],[[\"@route\",\"@model\"],[\"budgets.budget.edit\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"budget.edit.title\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,0],[[24,0,\"budget-card__link\"]],[[\"@route\",\"@model\"],[\"budgets.budget.budget-periods\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"budget-periods.title\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@budget\"],false,[\"link-to\",\"budgets/delete-button\",\"t\"]]",
    "moduleName": "fx/components/budgets/budget-card.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});