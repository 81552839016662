define("fx/components/reports/product-quantities-by-currency-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <th>
      {{@header}}
    </th>
  
    {{#each @dataset as |value|}}
      <Reports::NumericCell
        @value={{value.units}}
      />
    {{/each}}
  
    <Reports::NumericCell
      @value={{@total.units}}
    />
  
    {{yield}}
  </tr>
  */
  {
    "id": "8JqprRhG",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[10,\"th\"],[12],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,3]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@value\"],[[30,4,[\"units\"]]]],null],[1,\"\\n\"]],[4]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@value\"],[[30,5,[\"units\"]]]],null],[1,\"\\n\\n  \"],[18,6,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@header\",\"@dataset\",\"value\",\"@total\",\"&default\"],false,[\"each\",\"-track-array\",\"reports/numeric-cell\",\"yield\"]]",
    "moduleName": "fx/components/reports/product-quantities-by-currency-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});