define("fx/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zcu4qQzB",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\\n\"],[10,\"main\"],[14,0,\"no-bg\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"content--x-small\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[14,0,\"text-center no-margin--bottom\"],[12],[1,[28,[35,2],[\"authentication.login.title\"],null]],[13],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@tenantInvite\",\"@scope\"],[[30,0,[\"tenantInvite\"]],\"login\"]],null],[1,\"\\n\\n    \"],[10,2],[14,0,\"text-center no-margin--top\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"or\"],null]],[1,\"\\n      \"],[8,[39,4],null,[[\"@route\",\"@query\"],[\"sign-up\",[28,[37,5],null,[[\"token\"],[[30,0,[\"token\"]]]]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[\"authentication.login.sign-up\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,6],null,[[\"@inviteToken\",\"@tenantInvite\"],[[30,0,[\"token\"]],[30,0,[\"tenantInvite\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"header/main\",\"content\",\"t\",\"tenant-invite/pending-invite\",\"link-to\",\"hash\",\"authentication/login-form\"]]",
    "moduleName": "fx/templates/login.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});