define("fx/components/dropdown/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="dropdown__trigger"
    role="button"
    ...attributes
    {{on "click" @onClick}}
  >
    {{yield}}
  </div>
  */
  {
    "id": "TnQDd8B4",
    "block": "[[[11,0],[24,0,\"dropdown__trigger\"],[24,\"role\",\"button\"],[17,1],[4,[38,0],[\"click\",[30,2]],null],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@onClick\",\"&default\"],false,[\"on\",\"yield\"]]",
    "moduleName": "fx/components/dropdown/trigger.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});