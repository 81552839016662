define("fx/templates/budgets/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "++9QDryL",
    "block": "[[[10,\"main\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@root\"],[\"budgets\"]],null],[1,\"\\n\\n    \"],[10,\"h1\"],[12],[1,[28,[35,2],[\"budgets.new.title\"],null]],[13],[1,\"\\n\\n    \"],[11,\"form\"],[4,[38,3],[\"submit\",[30,0,[\"budgetCreate\"]]],null],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@budget\",\"@currencies\",\"@selectedPrimaryCurrency\",\"@errors\"],[[30,0,[\"budget\"]],[30,0,[\"currencies\"]],[30,0,[\"budget\",\"primaryCurrency\"]],[30,0,[\"errors\"]]]],null],[1,\"\\n\\n      \"],[8,[39,5],null,[[\"@budget\",\"@products\",\"@errors\"],[[30,0,[\"budget\"]],[30,0,[\"products\"]],[30,0,[\"errors\"]]]],null],[1,\"\\n\\n      \"],[8,[39,6],null,[[\"@budget\",\"@errors\"],[[30,0,[\"budget\"]],[30,0,[\"errors\"]]]],null],[1,\"\\n\\n      \"],[10,0],[14,0,\"form__actions\"],[12],[1,\"\\n        \"],[8,[39,7],null,[[\"@type\"],[\"submit\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,2],[\"save\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[46,[28,[37,9],null,null],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"content\",\"breadcrumb\",\"t\",\"on\",\"budgets/currencies-form\",\"budgets/products-form\",\"budgets/base-form\",\"data-button\",\"component\",\"-outlet\"]]",
    "moduleName": "fx/templates/budgets/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});