define("fx/templates/password/restore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ki4vDhF0",
    "block": "[[[10,\"h1\"],[14,0,\"text-center no-margin--bottom\"],[12],[1,[28,[35,0],[\"password.restore.title\"],null]],[13],[1,\"\\n\\n\"],[11,\"form\"],[24,0,\"box\"],[4,[38,1],[\"submit\",[30,0,[\"sendReset\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"form__row\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,\"for\",\"password\"],[14,0,\"label--required\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[\"account.profile.password\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[11,\"input\"],[24,\"autocomplete\",\"password\"],[24,1,\"password\"],[24,\"required\",\"\"],[16,2,[30,0,[\"password\"]]],[24,4,\"password\"],[4,[38,1],[\"change\",[30,0,[\"updatePassword\"]]],null],[12],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@errors\",\"@attribute\"],[[30,0,[\"errors\"]],\"password\"]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"form__row\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,\"for\",\"password-confirmation\"],[14,0,\"label--required\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[\"account.profile.password-confirmation\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[11,\"input\"],[24,\"autocomplete\",\"password-confirmation\"],[24,1,\"password-confirmation\"],[24,\"required\",\"\"],[16,2,[30,0,[\"passwordConfirmation\"]]],[24,4,\"password\"],[4,[38,1],[\"change\",[30,0,[\"updatePasswordConfirmation\"]]],null],[12],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@errors\",\"@attribute\"],[[30,0,[\"errors\"]],\"passwordConfirmation\"]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"form__actions\"],[12],[1,\"\\n    \"],[10,\"button\"],[14,0,\"button button--large button--full\"],[14,4,\"submit\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[\"password.restore.submit\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"t\",\"on\",\"field-errors\"]]",
    "moduleName": "fx/templates/password/restore.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});