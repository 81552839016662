define("fx/templates/budgets/budget/budget-periods/budget-period/monitoring/unrealized-product-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6olR5HNs",
    "block": "[[[10,\"h1\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"monitoring.unrealized-product-results.title\"],null]],[1,\"\\n  \"],[8,[39,1],null,[[\"@budgetPeriod\",\"@analysis\"],[[30,0,[\"budgetPeriod\"]],[30,0,[\"analysis\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,2],null,[[\"@refetchQueries\"],[[28,[37,3],[\"UnrealizedResults\",\"ProfitabilityByProductAndHedgingChart\",\"MarginGapChart\"],null]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@unrealizedProductResults\"],[[30,0,[\"unrealizedProductResults\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@profitabilityByProductAndHedging\"],[[30,0,[\"profitabilityByProductAndHedging\"]]]],null],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@marginGap\"],[[30,0,[\"marginGap\"]]]],null],[1,\"\\n\\n\"],[8,[39,7],null,null,null],[1,\"\\n\"]],[],false,[\"t\",\"monitoring/heading\",\"monitoring/currency-rate-override-table\",\"array\",\"monitoring/unrealized-product-results-table\",\"monitoring/profitability-by-product-and-hedging-chart\",\"monitoring/margin-gap-chart\",\"flow-bar\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/monitoring/unrealized-product-results.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});