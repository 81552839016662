define("fx/components/link-to-external", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <a
    href="#"
    rel="noopener"
    ...attributes
  >
    {{yield}}
  </a>
  */
  {
    "id": "MH7baSb/",
    "block": "[[[11,3],[24,6,\"#\"],[24,\"rel\",\"noopener\"],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "fx/components/link-to-external.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});