define("fx/templates/budgets/budget/budget-periods/budget-period/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DF8yIDpb",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,0],[\"budget-period.edit.title\"],null]],[13],[1,\"\\n\\n\"],[11,\"form\"],[4,[38,1],[\"submit\",[30,0,[\"budgetPeriodUpdate\"]]],null],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@budgetPeriod\",\"@currencies\",\"@primaryCurrency\",\"@errors\"],[[30,0,[\"budgetPeriod\"]],[30,0,[\"currencies\"]],[30,0,[\"budget\",\"primaryCurrency\"]],[30,0,[\"errors\"]]]],null],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@budgetPeriod\",\"@products\",\"@errors\"],[[30,0,[\"budgetPeriod\"]],[30,0,[\"products\"]],[30,0,[\"errors\"]]]],null],[1,\"\\n\\n  \"],[10,0],[14,0,\"form__actions\"],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@type\"],[\"submit\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,0],[\"save\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"t\",\"on\",\"budget-period/currencies-form\",\"budget-period/products-form\",\"data-button\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});