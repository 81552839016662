define("fx/templates/budgets/budget/budget-periods/budget-period/reports/financial-flow-totals/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "moEKVadq",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,0],[\"reports.financial-flow-totals.title\"],null]],[13],[1,\"\\n\\n\"],[10,2],[14,0,\"instructions\"],[12],[1,[28,[35,0],[\"reports.financial-flow-totals.instructions\"],null]],[13],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"financialFlowTotals\",\"datasets\"]]],null]],null],null,[[[1,\"  \"],[8,[39,3],null,[[\"@data\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[8,[39,4],null,[[\"@nextLabel\",\"@nextStep\"],[[28,[37,0],[\"flow.next-section\"],null],\"budgets.budget.budget-periods.budget-period.hedging.index\"]],null]],[\"data\"],false,[\"t\",\"each\",\"-track-array\",\"reports/financial-flow-totals-table\",\"flow-bar\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/reports/financial-flow-totals/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});