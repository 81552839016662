define("fx/templates/account/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dOBeQI1T",
    "block": "[[[10,\"main\"],[14,0,\"no-bg\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"content--x-small\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[12],[1,[28,[35,1],[\"account.profile.title\"],null]],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@errors\",\"@user\",\"@onSubmit\"],[[30,0,[\"errors\"]],[30,0,[\"currentUser\",\"user\"]],[30,0,[\"updateUser\"]]]],null],[1,\"\\n\\n    \"],[10,\"h2\"],[12],[1,[28,[35,1],[\"account.profile.security\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"box\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"form__row\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,\"for\",\"password\"],[14,0,\"label--required label--disabled\"],[12],[1,\"\\n          \"],[1,[28,[35,1],[\"account.profile.password\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"form__row\"],[12],[1,\"\\n          \"],[10,\"input\"],[14,3,\"password\"],[14,\"placeholder\",\"********\"],[14,\"disabled\",\"\"],[14,4,\"password\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,3],[[24,0,\"button\"]],[[\"@route\"],[\"account.update-password\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"account.profile.change_password\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"content\",\"t\",\"account/profile-form\",\"link-to\"]]",
    "moduleName": "fx/templates/account/profile.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});