define("fx/templates/budgets/budget/budget-periods/budget-period/hedging/analysis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7l1AF3GI",
    "block": "[[[41,[30,0,[\"displayNonPrimaryNotice\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@target\"],[\"before-breadcrumb\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"notice\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"hedging.analysis.non-primary-notice\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[46,[28,[37,4],null,null],null,null,null]],[],false,[\"if\",\"portal\",\"t\",\"component\",\"-outlet\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/hedging/analysis.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});