define("fx/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Re1rMSug",
    "block": "[[[10,0],[15,0,[29,[\"\\n    fx-app\\n    page-with-header\\n    \",[52,[30,0,[\"loading\",\"isLoading\"]],\"loading\"],\"\\n  \"]]],[12],[1,\"\\n  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\\n  \"],[8,[39,3],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@message\"],[[30,0,[\"loading\",\"message\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,null,null],[1,\"\\n\\n\"],[10,0],[14,0,\"flash-messages\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"flashMessages\",\"queue\"]]],null]],null],null,[[[1,\"    \"],[8,[39,8],null,[[\"@flash\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,3,[\"sticky\"]],[[[1,\"        \"],[10,0],[14,0,\"alert__content\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"sticky-message-container\"],[12],[1,\"\\n            \"],[10,0],[12],[1,\"\\n              \"],[1,[30,3,[\"message\"]]],[1,\"\\n            \"],[13],[1,\"\\n            \"],[11,\"button\"],[24,0,\"button button--plain\"],[24,4,\"button\"],[4,[38,9],[\"click\",[30,4]],null],[12],[1,\"\\n              \"],[1,[28,[35,10],[\"/images/circle-xmark\"],[[\"class\"],[\"circle-xmark-icon\"]]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"alert__content\"],[12],[1,\"\\n          \"],[1,[30,3,[\"message\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"]],[2,3,4]]]]],[1,\"\\n\"]],[1]],null],[13]],[\"flash\",\"component\",\"flash\",\"close\"],false,[\"if\",\"component\",\"-outlet\",\"footer\",\"loading-indicator\",\"unsaved-change-loss-confirmation\",\"each\",\"-track-array\",\"flash-message\",\"on\",\"inline-svg\"]]",
    "moduleName": "fx/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});