define("fx/components/hedging/anticipated-impact-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <Table::DateHeader
      @date={{@month}}
      @header={{@header}}
    />
  
    {{#each @categories as |category|}}
      <Reports::CurrencyCell
        @value={{get @dataset category}}
      />
    {{/each}}
  
    {{yield}}
  </tr>
  */
  {
    "id": "HIiOiSTV",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@date\",\"@header\"],[[30,2],[30,3]]],null],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,4]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@value\"],[[28,[37,4],[[30,6],[30,5]],null]]],null],[1,\"\\n\"]],[5]],null],[1,\"\\n  \"],[18,7,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@month\",\"@header\",\"@categories\",\"category\",\"@dataset\",\"&default\"],false,[\"table/date-header\",\"each\",\"-track-array\",\"reports/currency-cell\",\"get\",\"yield\"]]",
    "moduleName": "fx/components/hedging/anticipated-impact-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});