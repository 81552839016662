define("fx/templates/budgets/budget/budget-periods/budget-period/reports/sensitivity-impact-in-primary/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SLBhzHcE",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,0],[\"reports.sensitivity-impact-in-primary.title\"],null]],[13],[1,\"\\n\"],[8,[39,1],null,[[\"@budgetPeriod\",\"@movements\",\"@refetchQueries\"],[[30,0,[\"budgetPeriod\"]],[30,0,[\"movements\"]],[28,[37,2],[\"SensitivityImpactInPrimary\"],null]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@budgetProfit\",\"@sensitivityImpact\",\"@title\"],[[30,0,[\"budgetProfit\"]],[30,0,[\"sensitivityImpactInPrimary\"]],[28,[37,0],[\"reports.sensitivity-impact-in-primary.chart.title\"],null]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@sensitivityImpact\"],[[30,0,[\"sensitivityImpactInPrimary\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"table-export__title\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"reports.sensitivity-impact-in-primary.table.title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"ol\"],[14,0,\"report-table__caption-content\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"reports.sensitivity-impact-in-primary.table.description\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,5],null,null,null]],[],false,[\"t\",\"movement-changer\",\"array\",\"reports/sensitivity-impact-budget-gap-chart\",\"reports/sensitivity-impact-table\",\"flow-bar\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/reports/sensitivity-impact-in-primary/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});