define("fx/templates/budgets/budget/budget-periods/budget-period/data/yearly-totals/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SRfwyTPB",
    "block": "[[[10,\"h1\"],[14,1,\"yearly-totals-title\"],[12],[1,[28,[35,0],[\"yearly-totals.title\"],null]],[13],[1,\"\\n\\n\"],[10,\"form\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"form__table\"],[24,\"aria-labelledby\",\"yearly-totals-title\"]],[[\"@table\",\"@valueFormat\",\"@displayRowTotals\",\"@errors\"],[[30,0,[\"table\"]],\"currency\",true,[30,0,[\"errors\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@save\"],[[30,0,[\"yearlyTotalsUpdate\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@target\"],[\"sidebar\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"yearly-totals.description\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"t\",\"data-table\",\"flow-bar\",\"portal\",\"sidebar\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/data/yearly-totals/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});