define("fx/components/hedging/overview-prices-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @dataset}}
    <tr ...attributes>
      <th>
        {{@header}}
      </th>
  
      <Reports::CurrencyCell
        @value={{@dataset.ratedValue}}
      />
  
      <Reports::CurrencyCell
        @value={{@dataset.value}}
      />
  
      {{yield}}
    </tr>
  {{/if}}
  */
  {
    "id": "gFZiUXw9",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,\"tr\"],[17,2],[12],[1,\"\\n    \"],[10,\"th\"],[12],[1,\"\\n      \"],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@value\"],[[30,1,[\"ratedValue\"]]]],null],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@value\"],[[30,1,[\"value\"]]]],null],[1,\"\\n\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@dataset\",\"&attrs\",\"@header\",\"&default\"],false,[\"if\",\"reports/currency-cell\",\"yield\"]]",
    "moduleName": "fx/components/hedging/overview-prices-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});