define("fx/utils/cell-helpers", ["exports", "numeral", "moment"], function (_exports, _numeral, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cellValuePointer = cellValuePointer;
  _exports.cellValue = cellValue;
  _exports.numeralCellValue = numeralCellValue;
  _exports.dateCellValue = dateCellValue;
  _exports.cellValueParent = cellValueParent;
  _exports.setCellValue = setCellValue;
  _exports.default = void 0;

  function cellValuePointer(cell) {
    return cell.valuePointer.join('.');
  }

  function cellValue(cell) {
    return Ember.get(cell, cellValuePointer(cell)) || 0;
  }

  function numeralCellValue(cell) {
    return (0, _numeral.default)(cellValue(cell)).value();
  }

  function dateCellValue(cell) {
    return _moment.default.utc(Ember.get(cell, cellValuePointer(cell))).toDate();
  } // Returns the pointer to the parent of the terminal cell value.


  function cellValueParent(cell) {
    return cell.valuePointer.slice(0, -1).join('.');
  }

  function setCellValue(cell, value) {
    return Ember.set(cell, cellValuePointer(cell), value);
  }

  var _default = {
    cellValue: cellValue,
    cellValueParent: cellValueParent,
    cellValuePointer: cellValuePointer,
    numeralCellValue: numeralCellValue,
    setCellValue: setCellValue
  };
  _exports.default = _default;
});