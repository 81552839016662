define("fx/templates/tenants/tenant/plans/success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/6qeC7Gt",
    "block": "[[[10,\"main\"],[14,0,\"no-bg\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"content--x-small\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[1,[28,[35,1],[\"plans.success.title\"],null]],[13],[1,\"\\n    \"],[10,2],[14,0,\"text-center large-text\"],[12],[1,[28,[35,1],[\"plans.success.content\"],null]],[13],[1,\"\\n\\n    \"],[8,[39,2],[[24,0,\"button button--large button--full\"]],[[\"@route\"],[\"currencies\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,1],[\"plans.success.start\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"content\",\"t\",\"link-to\",\"component\",\"-outlet\"]]",
    "moduleName": "fx/templates/tenants/tenant/plans/success.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});