define("fx/components/reports/monthly-sales-ratios-by-currency-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <Table::DateHeader
      @date={{@month}}
    />
  
    {{#each @dataset as |value|}}
      <Reports::PercentCell
        @value={{value.ratio}}
      />
    {{/each}}
  
    <Reports::CurrencyCell
      @value={{@monthlySales}}
    />
  
    <Reports::PercentCell
      @value={{@relativeSignificance}}
    />
  
    {{yield}}
  </tr>
  */
  {
    "id": "qjCv7fEG",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@date\"],[[30,2]]],null],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,3]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@value\"],[[30,4,[\"ratio\"]]]],null],[1,\"\\n\"]],[4]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@value\"],[[30,5]]],null],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@value\"],[[30,6]]],null],[1,\"\\n\\n  \"],[18,7,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@month\",\"@dataset\",\"value\",\"@monthlySales\",\"@relativeSignificance\",\"&default\"],false,[\"table/date-header\",\"each\",\"-track-array\",\"reports/percent-cell\",\"reports/currency-cell\",\"yield\"]]",
    "moduleName": "fx/components/reports/monthly-sales-ratios-by-currency-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});