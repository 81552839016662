define("fx/templates/password/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4ToTLZUP",
    "block": "[[[10,\"h1\"],[14,0,\"text-center no-margin--bottom\"],[12],[1,[28,[35,0],[\"password.new.title\"],null]],[13],[1,\"\\n\\n\"],[10,2],[14,0,\"text-center no-margin--top\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"sign-up.account-already-without-email\"],null]],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@query\"],[\"login\",[28,[37,2],null,[[\"token\"],[[30,0,[\"token\"]]]]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"sign-up.sign-in\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[11,\"form\"],[24,0,\"box\"],[4,[38,3],[\"submit\",[30,0,[\"sendReset\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"form__row\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,\"for\",\"email\"],[14,0,\"label--required\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[\"authentication.login.email\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[11,\"input\"],[24,\"autocomplete\",\"username\"],[24,1,\"email\"],[24,\"required\",\"\"],[16,2,[30,0,[\"email\"]]],[24,4,\"email\"],[4,[38,3],[\"change\",[30,0,[\"updateEmail\"]]],null],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"form__actions\"],[12],[1,\"\\n    \"],[10,\"button\"],[14,0,\"button button--large button--full\"],[14,4,\"submit\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[\"password.new.submit\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"t\",\"link-to\",\"hash\",\"on\"]]",
    "moduleName": "fx/templates/password/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});