define("fx/templates/budgets/budget/budget-periods/budget-period/monitoring/market-sales-gap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZAjvVLbW",
    "block": "[[[10,\"h1\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"monitoring.market-sales-gap.title\"],null]],[1,\"\\n  \"],[8,[39,1],null,[[\"@budgetPeriod\",\"@analysis\"],[[30,0,[\"budgetPeriod\"]],[30,0,[\"analysis\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,2],null,[[\"@refetchQueries\"],[[28,[37,3],[\"MarketSalesGap\"],null]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@marketSalesGap\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@profitability\"],[[30,0,[\"model\",\"profitability\"]]]],null],[1,\"\\n\\n\"],[8,[39,6],null,null,null],[1,\"\\n\"]],[],false,[\"t\",\"monitoring/heading\",\"monitoring/currency-rate-override-table\",\"array\",\"monitoring/market-sales-gap-table\",\"monitoring/market-sales-gap-profitability-table\",\"flow-bar\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/monitoring/market-sales-gap.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});