define("fx/components/reports/summary-elements-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <th>
      {{@header}}
    </th>
  
    {{#each @categories as |category|}}
      <Reports::SummaryElementsCell
        @value={{get @dataset category}}
        @category={{category}}
      />
    {{/each}}
  
    {{yield}}
  </tr>
  */
  {
    "id": "6AG0aalh",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[10,\"th\"],[12],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,3]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@value\",\"@category\"],[[28,[37,3],[[30,5],[30,4]],null],[30,4]]],null],[1,\"\\n\"]],[4]],null],[1,\"\\n  \"],[18,6,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@header\",\"@categories\",\"category\",\"@dataset\",\"&default\"],false,[\"each\",\"-track-array\",\"reports/summary-elements-cell\",\"get\",\"yield\"]]",
    "moduleName": "fx/components/reports/summary-elements-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});