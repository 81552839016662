define("fx/components/plan-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="plan-card">
    <div>
      <h2 class="plan-card__name">
        {{t (concat "plans.interval." @plan.interval)}}
      </h2>
      <div class="plan-card__price">
        {{format-number
          @plan.price.amount
          style="currency"
          minimumFractionDigits="0"
          maximumFractionDigits="0"
          currency=@plan.price.isoCode
        }}
        <span class="plan-card__price-interval">
          {{t (concat "plans.index.interval." @plan.interval)}}
        </span>
      </div>
  
      <p>
        {{t (concat "plans.index.description." @plan.interval)}}
      </p>
  
      {{yield}}
    </div>
  
    <div class="box__actions">
      <button
        type="button"
        class="button--large button--full"
        {{on "click" (fn @subscribe @plan)}}
      >
        {{t "plans.subscribe"}}
      </button>
    </div>
  </div>
  */
  {
    "id": "OfPczP8C",
    "block": "[[[10,0],[14,0,\"plan-card\"],[12],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"plan-card__name\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[[28,[37,1],[\"plans.interval.\",[30,1,[\"interval\"]]],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"plan-card__price\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[[30,1,[\"price\",\"amount\"]]],[[\"style\",\"minimumFractionDigits\",\"maximumFractionDigits\",\"currency\"],[\"currency\",\"0\",\"0\",[30,1,[\"price\",\"isoCode\"]]]]]],[1,\"\\n      \"],[10,1],[14,0,\"plan-card__price-interval\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[[28,[37,1],[\"plans.index.interval.\",[30,1,[\"interval\"]]],null]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,0],[[28,[37,1],[\"plans.index.description.\",[30,1,[\"interval\"]]],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"box__actions\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"button--large button--full\"],[24,4,\"button\"],[4,[38,4],[\"click\",[28,[37,5],[[30,2],[30,1]],null]],null],[12],[1,\"\\n      \"],[1,[28,[35,0],[\"plans.subscribe\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@plan\",\"@subscribe\",\"&default\"],false,[\"t\",\"concat\",\"format-number\",\"yield\",\"on\",\"fn\"]]",
    "moduleName": "fx/components/plan-card.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});