define("fx/templates/budgets/budget/batch-forward-transactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MJs25a0S",
    "block": "[[[10,\"main\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@root\"],[\"budgets\"]],null],[1,\"\\n\\n    \"],[10,\"h1\"],[12],[1,[28,[35,2],[\"batch-forward-transactions.title\"],null]],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"forward-transactions.informations\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"tables\"]]],null]],null],null,[[[1,\"      \"],[8,[39,5],[[24,0,\"form__table\"]],[[\"@table\",\"@valueFormat\",\"@rowComponent\",\"@errors\"],[[30,1],\"percent\",\"batch-forward-transactions-row\",[30,0,[\"errors\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"Caption\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[10,0],[14,0,\"table-export__title\"],[12],[1,\"\\n            \"],[1,[28,[35,2],[\"budget-period.range\"],[[\"start\",\"end\"],[[28,[37,6],[[30,1,[\"budgetPeriod\",\"startsOn\"]]],[[\"timeZone\",\"month\",\"day\",\"year\"],[\"UTC\",\"long\",\"numeric\",\"numeric\"]]],[28,[37,6],[[30,1,[\"budgetPeriod\",\"endsOn\"]]],[[\"timeZone\",\"month\",\"day\",\"year\"],[\"UTC\",\"long\",\"numeric\",\"numeric\"]]]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[2]]]]],[1,\"\\n\"]],[1]],null],[1,\"  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,7],null,[[\"@save\",\"@nextStep\",\"@hideText\"],[[30,0,[\"forwardTransactionBatchCreate\"]],\"budgets.budget.budget-periods\",true]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,8],[[24,0,\"flow-bar__wrap\"]],[[\"@name\"],[\"flow-bar\"]],null]],[\"table\",\"DataTable\"],false,[\"content\",\"breadcrumb\",\"t\",\"each\",\"-track-array\",\"data-table\",\"format-date\",\"flow-bar\",\"portal-target\"]]",
    "moduleName": "fx/templates/budgets/budget/batch-forward-transactions.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});