define("fx/templates/budgets/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HYMb4kUC",
    "block": "[[[10,\"main\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"heading\"],[12],[1,\"\\n      \"],[10,\"h1\"],[12],[1,[28,[35,1],[\"budgets.title\"],null]],[13],[1,\"\\n      \"],[8,[39,2],[[24,0,\"button budgets-index__new\"]],[[\"@route\"],[\"budgets.new\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"budgets.new.title\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"budgets-index\"],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"budgets-index__list\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"budgets\"]]],null]],null],null,[[[1,\"          \"],[8,[39,5],[[24,0,\"budgets-index__row\"]],[[\"@budget\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"sidebar__wrap\"],[12],[1,\"\\n    \"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,2],[12],[1,[28,[35,1],[\"budgets.index.description\"],[[\"htmlSafe\"],[true]]]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"budget\"],false,[\"content\",\"t\",\"link-to\",\"each\",\"-track-array\",\"budgets/budget-card\",\"sidebar\"]]",
    "moduleName": "fx/templates/budgets/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});