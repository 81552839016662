define("fx/templates/sign-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xGViIeUU",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\\n\"],[10,\"main\"],[14,0,\"no-bg\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"content--x-small\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[14,0,\"text-center no-margin--bottom\"],[12],[1,[28,[35,2],[\"sign-up.title\"],null]],[13],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@tenantInvite\",\"@scope\"],[[30,0,[\"tenantInvite\"]],\"sign-up\"]],null],[1,\"\\n\\n    \"],[10,2],[14,0,\"text-center no-margin--top\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"sign-up.account-already\"],[[\"email\",\"htmlSafe\"],[[30,0,[\"user\",\"email\"]],true]]]],[1,\"\\n      \"],[8,[39,4],null,[[\"@route\",\"@query\"],[\"login\",[28,[37,5],null,[[\"token\"],[[30,0,[\"token\"]]]]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[\"sign-up.sign-in\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,6],null,[[\"@user\",\"@errors\",\"@onSubmit\",\"@displayPasswordFields\",\"@saveLabel\",\"@disableEmailField\"],[[30,0,[\"user\"]],[30,0,[\"errors\"]],[30,0,[\"createUser\"]],true,[28,[37,2],[\"sign-up.create-account\"],null],[30,0,[\"isAcceptingInvitation\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"header/main\",\"content\",\"t\",\"tenant-invite/pending-invite\",\"link-to\",\"hash\",\"account/profile-form\"]]",
    "moduleName": "fx/templates/sign-up.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});