define("fx/components/table/caption", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <caption
    ...attributes
  >
    <div class="report-table__caption space-between align-baseline">
      <div>
        {{yield}}
      </div>
  
      {{#if @exportable}}
        <Table::Export />
      {{/if}}
    </div>
    {{yield to="formContent"}}
  </caption>
  
  */
  {
    "id": "ZSKl6yZ8",
    "block": "[[[11,\"caption\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"report-table__caption space-between align-baseline\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"      \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@exportable\",\"&default\",\"&formContent\"],false,[\"yield\",\"if\",\"table/export\"]]",
    "moduleName": "fx/components/table/caption.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});