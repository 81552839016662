define("fx/templates/password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qf2fbzXD",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\\n\"],[10,\"main\"],[14,0,\"no-bg\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"content--x-small\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"header/main\",\"content\",\"component\",\"-outlet\"]]",
    "moduleName": "fx/templates/password.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});