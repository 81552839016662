define("fx/components/flow-bar/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
    class="flow-bar__button"
    type="button"
    {{on "click" @onClick}}
    ...attributes
  >
    {{yield}}
  </button>
  */
  {
    "id": "gBhi/D3T",
    "block": "[[[11,\"button\"],[24,0,\"flow-bar__button\"],[24,4,\"button\"],[17,1],[4,[38,0],[\"click\",[30,2]],null],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@onClick\",\"&default\"],false,[\"on\",\"yield\"]]",
    "moduleName": "fx/components/flow-bar/button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});