define("fx/components/table/date-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <th
    data-value={{@date}}
    ...attributes
  >
    {{#if (has-block)}}
      {{yield}}
    {{else if @header}}
      {{@header}}
    {{else}}
      {{format-date
        @date
        timeZone="UTC"
        month="long"
        year="numeric"
      }}
    {{/if}}
  </th>
  */
  {
    "id": "tIE1aOi8",
    "block": "[[[11,\"th\"],[16,\"data-value\",[30,1]],[17,2],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"    \"],[18,4,null],[1,\"\\n\"]],[]],[[[41,[30,3],[[[1,\"    \"],[1,[30,3]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,3],[[30,1]],[[\"timeZone\",\"month\",\"year\"],[\"UTC\",\"long\",\"numeric\"]]]],[1,\"\\n  \"]],[]]]],[]]],[13]],[\"@date\",\"&attrs\",\"@header\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"format-date\"]]",
    "moduleName": "fx/components/table/date-header.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});