define("fx/components/hedging/forward-contract-hedging-credit-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <Table::DateHeader
      @date={{@month}}
    />
  
    <Reports::CurrencyCell
      @value={{@dataset.outstandingEvolutionWithoutAllowance.value}}
    />
  
    <Reports::CurrencyCell
      @value={{@dataset.creditLineWithoutAllowance.value}}
    />
  
    <Reports::CurrencyCell
      @value={{@dataset.hedgingWithAllowance.value}}
    />
  
    <Reports::CurrencyCell
      @value={{@dataset.outstandingEvolutionWithAllowance.value}}
    />
  
    <Reports::CurrencyCell
      @value={{@dataset.creditLineWithAllowance.value}}
    />
  
    {{yield}}
  </tr>
  */
  {
    "id": "ErzRgVyf",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@date\"],[[30,2]]],null],[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@value\"],[[30,3,[\"outstandingEvolutionWithoutAllowance\",\"value\"]]]],null],[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@value\"],[[30,3,[\"creditLineWithoutAllowance\",\"value\"]]]],null],[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@value\"],[[30,3,[\"hedgingWithAllowance\",\"value\"]]]],null],[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@value\"],[[30,3,[\"outstandingEvolutionWithAllowance\",\"value\"]]]],null],[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@value\"],[[30,3,[\"creditLineWithAllowance\",\"value\"]]]],null],[1,\"\\n\\n  \"],[18,4,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@month\",\"@dataset\",\"&default\"],false,[\"table/date-header\",\"reports/currency-cell\",\"yield\"]]",
    "moduleName": "fx/components/hedging/forward-contract-hedging-credit-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});