define("fx/templates/tenants/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H1xH6hl+",
    "block": "[[[10,\"main\"],[14,0,\"no-bg\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"content--x-small\"]],[[\"@skipSpacer\"],[true]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[12],[1,[28,[35,1],[\"tenants.new.title\"],null]],[13],[1,\"\\n    \"],[11,\"form\"],[4,[38,2],[\"submit\",[30,0,[\"tenantCreate\"]]],null],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@tenant\",\"@errors\",\"@tenantOptions\",\"@countryOptions\",\"@activitySectorsOptions\"],[[30,0,[\"tenant\"]],[30,0,[\"errors\"]],[30,0,[\"tenantOptions\"]],[30,0,[\"countryOptions\"]],[30,0,[\"activitySectorsOptions\"]]]],null],[1,\"\\n\\n      \"],[10,0],[14,0,\"form__actions form__actions--space-between\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"button--large button--outline\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,0,[\"cancel\"]]],null],[12],[1,\"\\n          \"],[1,[28,[35,1],[\"cancel\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"button\"],[14,0,\"button--large\"],[14,4,\"submit\"],[12],[1,\"\\n          \"],[1,[28,[35,1],[\"tenants.new.submit\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"content\",\"t\",\"on\",\"tenants/form\",\"component\",\"-outlet\"]]",
    "moduleName": "fx/templates/tenants/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});