define("fx/templates/admin/users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ChxF/y+q",
    "block": "[[[10,\"main\"],[14,0,\"no-bg\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"heading\"],[12],[1,\"\\n      \"],[10,\"h1\"],[12],[1,[28,[35,1],[\"admin.users.index.title\"],null]],[13],[1,\"\\n      \"],[8,[39,2],[[24,0,\"button\"]],[[\"@route\"],[\"admin.users.invite\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"admin.users.index.new\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"table\"],[14,0,\"table\"],[12],[1,\"\\n      \"],[10,\"thead\"],[12],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"th\"],[12],[1,[28,[35,1],[\"users.name\"],null]],[13],[1,\"\\n          \"],[10,\"th\"],[12],[1,[28,[35,1],[\"users.title\"],null]],[13],[1,\"\\n          \"],[10,\"th\"],[12],[1,[28,[35,1],[\"users.email\"],null]],[13],[1,\"\\n          \"],[10,\"th\"],[12],[1,[28,[35,1],[\"admin.users.role\"],null]],[13],[1,\"\\n          \"],[10,\"th\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1]],null]],null],null,[[[1,\"          \"],[8,[39,5],null,[[\"@role\",\"@delete\"],[[30,2],[30,0,[\"deleteRole\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@model\",\"role\"],false,[\"content\",\"t\",\"link-to\",\"each\",\"-track-array\",\"roles/role-row\"]]",
    "moduleName": "fx/templates/admin/users/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});