define("fx/components/monitoring/alerts-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    {{#if @month}}
      <Table::DateHeader
        @date={{@month}}
      />
    {{else if @header}}
      <th>{{@header}}</th>
    {{else}}
      <th>{{t "total"}}</th>
    {{/if}}
  
    {{#each @dataset as |value|}}
      <Monitoring::WarnLevelCell
        @value={{value.tbdRemaining.amount.value}}
        @level={{value.tbdRemaining.warnLevel}}
      />
  
      <Monitoring::WarnLevelCell
        @value={{value.tbdLimit.amount.value}}
        @level={{value.tbdLimit.warnLevel}}
      />
    {{/each}}
  
    <Monitoring::WarnLevelCell
      @value={{@totals.tbdRemaining.amount.value}}
      @level={{@totals.tbdRemaining.warnLevel}}
    />
  
    <Monitoring::WarnLevelCell
      @value={{@totals.tbdLimit.amount.value}}
      @level={{@totals.tbdLimit.warnLevel}}
    />
  
    {{yield}}
  </tr>
  */
  {
    "id": "OX1f7uPs",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[8,[39,1],null,[[\"@date\"],[[30,2]]],null],[1,\"\\n\"]],[]],[[[41,[30,3],[[[1,\"    \"],[10,\"th\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"th\"],[12],[1,[28,[35,2],[\"total\"],null]],[13],[1,\"\\n  \"]],[]]]],[]]],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,4]],null]],null],null,[[[1,\"    \"],[8,[39,5],null,[[\"@value\",\"@level\"],[[30,5,[\"tbdRemaining\",\"amount\",\"value\"]],[30,5,[\"tbdRemaining\",\"warnLevel\"]]]],null],[1,\"\\n\\n    \"],[8,[39,5],null,[[\"@value\",\"@level\"],[[30,5,[\"tbdLimit\",\"amount\",\"value\"]],[30,5,[\"tbdLimit\",\"warnLevel\"]]]],null],[1,\"\\n\"]],[5]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@value\",\"@level\"],[[30,6,[\"tbdRemaining\",\"amount\",\"value\"]],[30,6,[\"tbdRemaining\",\"warnLevel\"]]]],null],[1,\"\\n\\n  \"],[8,[39,5],null,[[\"@value\",\"@level\"],[[30,6,[\"tbdLimit\",\"amount\",\"value\"]],[30,6,[\"tbdLimit\",\"warnLevel\"]]]],null],[1,\"\\n\\n  \"],[18,7,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@month\",\"@header\",\"@dataset\",\"value\",\"@totals\",\"&default\"],false,[\"if\",\"table/date-header\",\"t\",\"each\",\"-track-array\",\"monitoring/warn-level-cell\",\"yield\"]]",
    "moduleName": "fx/components/monitoring/alerts-table-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});