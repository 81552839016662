define("fx/templates/budgets/budget/budget-periods/budget-period/data/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "86BHXGgD",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,0],[\"data.title\"],null]],[13],[1,\"\\n\\n\"],[10,2],[14,0,\"instructions\"],[12],[1,[28,[35,0],[\"data.index.instructions\"],null]],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"step-link__wrap\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"flow\",\"sectionSteps\"]]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@step\",\"@stepStates\"],[[30,1],[30,0,[\"model\",\"budgetPeriodState\",\"steps\"]]]],null],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@save\",\"@saveLabel\",\"@disableSaveButton\",\"@displaySaveButton\"],[[30,0,[\"review\"]],[28,[37,0],[\"data.review\"],null],[52,[30,0,[\"model\",\"budgetPeriodState\",\"valid\"]],false,true],[30,0,[\"displaySaveButton\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,0],[\"data.completed-count\"],[[\"valid\",\"total\"],[[30,0,[\"validStepsCount\"]],[30,0,[\"stepsCount\"]]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@target\"],[\"sidebar\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"data.index.description\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"step\"],false,[\"t\",\"each\",\"-track-array\",\"step-link\",\"flow-bar\",\"if\",\"portal\",\"sidebar\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/data/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});