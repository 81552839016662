define("fx/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b5J0GekJ",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\\n\"],[10,\"main\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"content--x-small\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"errors.not-found.title\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[1,[28,[35,2],[\"errors.not-found.content\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"header/main\",\"content\",\"t\"]]",
    "moduleName": "fx/templates/not-found.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});