define("fx/templates/products/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4bnSOexm",
    "block": "[[[10,\"main\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"heading\"],[12],[1,\"\\n      \"],[10,\"h1\"],[12],[1,[28,[35,1],[\"products.title\"],null]],[13],[1,\"\\n      \"],[8,[39,2],[[24,0,\"button products-index__new\"]],[[\"@route\"],[\"products.new\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"products.new\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"products.index.description\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[44,[\"products\"],[[[1,\"      \"],[11,\"ol\"],[24,0,\"products-index__list index-list\"],[4,[38,4],null,[[\"groupName\",\"onChange\"],[[30,1],[30,0,[\"reorderItems\"]]]]],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"products\"]]],null]],null],null,[[[1,\"          \"],[11,\"li\"],[24,0,\"products-index__row\"],[4,[38,7],null,[[\"groupName\",\"model\",\"isDraggingDisabled\"],[[30,1],[30,2],[30,0,[\"currentUser\",\"isReadonly\"]]]]],[12],[1,\"\\n            \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"products.edit\",[30,2]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,2,[\"name\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n            \"],[8,[39,8],null,null,null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n\"]],[1]]],[1,\"  \"]],[]]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"sidebar__wrap\"],[12],[1,\"\\n    \"],[8,[39,9],null,null,[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,1],[\"products.index.instructions\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,10],null,[[\"@target\",\"@nextStep\"],[\"products-flow-bar\",\"budgets.index\"]],null]],[\"groupName\",\"product\"],false,[\"content\",\"t\",\"link-to\",\"let\",\"sortable-group\",\"each\",\"-track-array\",\"sortable-item\",\"data-handle\",\"sidebar\",\"flow-bar\"]]",
    "moduleName": "fx/templates/products/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});