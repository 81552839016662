define("fx/templates/budgets/budget/budget-periods/budget-period", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pmX6A57G",
    "block": "[[[10,\"main\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@name\"],[\"before-breadcrumb\"]],null],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@root\"],[\"budgets.budget.budget-periods\"]],null],[1,\"\\n\\n    \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,1],[[24,0,\"sidebar__wrap\"]],[[\"@name\"],[\"sidebar\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,1],[[24,0,\"flow-bar__wrap\"]],[[\"@name\"],[\"flow-bar\"]],null]],[],false,[\"content\",\"portal-target\",\"breadcrumb\",\"component\",\"-outlet\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});