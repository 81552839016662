define("fx/templates/authenticated/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Eh/B7hrT",
    "block": "[[[10,\"main\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"ul\"],[14,0,\"index-list\"],[12],[1,\"\\n\"],[41,[30,0,[\"currentTenant\",\"subscriptionActive\"]],[[[1,\"        \"],[10,\"li\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@route\"],[\"currencies\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,3],[\"currencies.title\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"li\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@route\"],[\"products\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,3],[\"products.title\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"li\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@route\"],[\"budgets\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,3],[\"budgets.title\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@route\"],[\"tenants\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,3],[\"tenants.title\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"content\",\"if\",\"link-to\",\"t\"]]",
    "moduleName": "fx/templates/authenticated/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});