define("fx/gql/subscriptions/refresh_notifications.graphql", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "subscription",
      "name": {
        "kind": "Name",
        "value": "RefreshNotifications"
      },
      "variableDefinitions": [],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "refreshNotifications"
          },
          "arguments": [],
          "directives": []
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 61
    }
  };
  var _default = doc;
  _exports.default = _default;
});