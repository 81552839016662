define("fx/components/monitoring/heading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="subtitle subtitle--small">
      <span>{{t "hedging.analyses.active"}}: <AnalysisName @analysis={{@analysis}} /></span>
      <br/>
      <span class="chip {{if @budgetPeriod.hasTransactions 'info--chip' 'warning--chip'}} tracking-basis">
        {{#if @budgetPeriod.hasTransactions}}
          {{t "monitoring.heading.tracking-basis.realized-coverage"}}
        {{else}}
          {{t "monitoring.heading.tracking-basis.suggested-coverage"}}
        {{/if}}
      </span>
  </span>
  
  */
  {
    "id": "o6EABfS0",
    "block": "[[[10,1],[14,0,\"subtitle subtitle--small\"],[12],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,0],[\"hedging.analyses.active\"],null]],[1,\": \"],[8,[39,1],null,[[\"@analysis\"],[[30,1]]],null],[13],[1,\"\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n    \"],[10,1],[15,0,[29,[\"chip \",[52,[30,2,[\"hasTransactions\"]],\"info--chip\",\"warning--chip\"],\" tracking-basis\"]]],[12],[1,\"\\n\"],[41,[30,2,[\"hasTransactions\"]],[[[1,\"        \"],[1,[28,[35,0],[\"monitoring.heading.tracking-basis.realized-coverage\"],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,0],[\"monitoring.heading.tracking-basis.suggested-coverage\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@analysis\",\"@budgetPeriod\"],false,[\"t\",\"analysis-name\",\"if\"]]",
    "moduleName": "fx/components/monitoring/heading.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});