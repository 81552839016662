define("fx/components/monitoring/unrealized-product-results-total-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <th>
      {{@header}}
    </th>
  
    <Reports::CurrencyCell
      @value={{@total.value}}
    />
  
    {{yield}}
  </tr>
  */
  {
    "id": "xfSGCtnh",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[10,\"th\"],[12],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@value\"],[[30,3,[\"value\"]]]],null],[1,\"\\n\\n  \"],[18,4,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@header\",\"@total\",\"&default\"],false,[\"reports/currency-cell\",\"yield\"]]",
    "moduleName": "fx/components/monitoring/unrealized-product-results-total-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});