define("fx/components/monitoring/market-sales-gap-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <th rowspan="2">
      {{@header}}
    </th>
  
    <th>{{t "monitoring.market-sales-gap.without-hedging"}}</th>
  
    {{#each (get @dataset "withoutHedging.currencies") as |data|}}
      <Reports::CurrencyCell
        @value={{data.value}}
      />
    {{/each}}
  
    <Reports::CurrencyCell
      @value={{@dataset.withoutHedging.total.value}}
    />
  
    {{yield}}
  </tr>
  
  <tr ...attributes>
    <th>
      {{t "monitoring.market-sales-gap.with-hedging"}}
    </th>
  
    {{#each (get @dataset "withHedging.currencies") as |data|}}
      <Reports::CurrencyCell
        @value={{data.value}}
      />
    {{/each}}
  
    <Reports::CurrencyCell
      @value={{@dataset.withHedging.total.value}}
    />
  </tr>
  */
  {
    "id": "xp1Lcbjg",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[10,\"th\"],[14,\"rowspan\",\"2\"],[12],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"th\"],[12],[1,[28,[35,0],[\"monitoring.market-sales-gap.without-hedging\"],null]],[13],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[28,[37,3],[[30,3],\"withoutHedging.currencies\"],null]],null]],null],null,[[[1,\"    \"],[8,[39,4],null,[[\"@value\"],[[30,4,[\"value\"]]]],null],[1,\"\\n\"]],[4]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@value\"],[[30,3,[\"withoutHedging\",\"total\",\"value\"]]]],null],[1,\"\\n\\n  \"],[18,6,null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[10,\"th\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"monitoring.market-sales-gap.with-hedging\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[28,[37,3],[[30,3],\"withHedging.currencies\"],null]],null]],null],null,[[[1,\"    \"],[8,[39,4],null,[[\"@value\"],[[30,5,[\"value\"]]]],null],[1,\"\\n\"]],[5]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@value\"],[[30,3,[\"withHedging\",\"total\",\"value\"]]]],null],[1,\"\\n\"],[13]],[\"&attrs\",\"@header\",\"@dataset\",\"data\",\"data\",\"&default\"],false,[\"t\",\"each\",\"-track-array\",\"get\",\"reports/currency-cell\",\"yield\"]]",
    "moduleName": "fx/components/monitoring/market-sales-gap-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});