define("fx/components/reports/allocation-vs-sales-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr>
    <th>{{@header}}</th>
    <Reports::SalesBasedAllocationCell
      @value={{@dataset.cogsTotals}}
    />
    <th>
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{t "reports.allocation-vs-sales.sales-for-currency" currency=@header}}
      {{/if}}
    </th>
    <Reports::SalesBasedAllocationCell
      @value={{@dataset.foreignCogs}}
    />
    <Reports::SalesBasedAllocationCell
      @value={{@dataset.primaryCogs}}
    />
  </tr>
  */
  {
    "id": "TR1yZCXy",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n  \"],[10,\"th\"],[12],[1,[30,1]],[13],[1,\"\\n  \"],[8,[39,0],null,[[\"@value\"],[[30,2,[\"cogsTotals\"]]]],null],[1,\"\\n  \"],[10,\"th\"],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"      \"],[18,3,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,4],[\"reports.allocation-vs-sales.sales-for-currency\"],[[\"currency\"],[[30,1]]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[8,[39,0],null,[[\"@value\"],[[30,2,[\"foreignCogs\"]]]],null],[1,\"\\n  \"],[8,[39,0],null,[[\"@value\"],[[30,2,[\"primaryCogs\"]]]],null],[1,\"\\n\"],[13]],[\"@header\",\"@dataset\",\"&default\"],false,[\"reports/sales-based-allocation-cell\",\"if\",\"has-block\",\"yield\",\"t\"]]",
    "moduleName": "fx/components/reports/allocation-vs-sales-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});