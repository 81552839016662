define("fx/components/hedging/foreign-exchange-transactions-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    {{#if @typeHeader}}
      <th rowspan="2">
        {{@typeHeader}}
  
        {{yield}}
      </th>
    {{/if}}
  
    <th>
      {{@header}}
    </th>
  
    {{#each @dataset as |data|}}
      <Hedging::ForeignExchangeTransactionsCurrency
        @data={{data}}
      />
    {{/each}}
  
    {{#if @total}}
      <Reports::CurrencyCell
        @value={{@total}}
      >
        {{#if @totalLabel}}
          <div class="data-cell__label">{{@totalLabel}}</div>
        {{/if}}
      </Reports::CurrencyCell>
    {{else}}
      <td></td>
    {{/if}}
  </tr>
  */
  {
    "id": "Z/zW31IK",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,\"th\"],[14,\"rowspan\",\"2\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n\\n      \"],[18,8,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,\"th\"],[12],[1,\"\\n    \"],[1,[30,3]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,4]],null]],null],null,[[[1,\"    \"],[8,[39,4],null,[[\"@data\"],[[30,5]]],null],[1,\"\\n\"]],[5]],null],[1,\"\\n\"],[41,[30,6],[[[1,\"    \"],[8,[39,5],null,[[\"@value\"],[[30,6]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,7],[[[1,\"        \"],[10,0],[14,0,\"data-cell__label\"],[12],[1,[30,7]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"td\"],[12],[13],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"@typeHeader\",\"@header\",\"@dataset\",\"data\",\"@total\",\"@totalLabel\",\"&default\"],false,[\"if\",\"yield\",\"each\",\"-track-array\",\"hedging/foreign-exchange-transactions-currency\",\"reports/currency-cell\"]]",
    "moduleName": "fx/components/hedging/foreign-exchange-transactions-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});