define("fx/components/monitoring/hedging-recalibration-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr
    class="hedging-recalibration-table__row {{if @stale "hedging-recalibration-table__row--stale"}}"
    ...attributes
  >
    <Table::DateHeader
      @date={{@dataset.month}}
    />
    <Reports::CurrencyCell
      @value={{@dataset.effectiveResidualExposure.value}}
    />
    <Reports::CurrencyCell
      @value={{@dataset.amountToHedge.value}}
    />
    <td>{{t (concat "monitoring.hedging-recalibration-table.category-options." @dataset.category)}}</td>
    <td>{{t (concat "monitoring.hedging-recalibration-table.direction-options." @dataset.direction)}}</td>
    {{#if (eq @dataset.showProfitAlert true)}}
      <td>—</td>
    {{else}}
      <Reports::NumericCell
        @value={{@dataset.forwardRateLimit}}
        @maximumFractionDigits={{6}}
      />
    {{/if}}
    <td class="report-table__actions" data-value="">
      <button
        type="button"
        class="button"
        disabled={{@stale}}
        {{on "click" (fn @openForwardTransactionModal @currency @dataset)}}
      >
        {{t "monitoring.hedging-recalibration-table.add-transaction"}}
      </button>
    </td>
    {{yield}}
  </tr>
  */
  {
    "id": "auy4eyda",
    "block": "[[[11,\"tr\"],[16,0,[29,[\"hedging-recalibration-table__row \",[52,[30,1],\"hedging-recalibration-table__row--stale\"]]]],[17,2],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@date\"],[[30,3,[\"month\"]]]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@value\"],[[30,3,[\"effectiveResidualExposure\",\"value\"]]]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@value\"],[[30,3,[\"amountToHedge\",\"value\"]]]],null],[1,\"\\n  \"],[10,\"td\"],[12],[1,[28,[35,3],[[28,[37,4],[\"monitoring.hedging-recalibration-table.category-options.\",[30,3,[\"category\"]]],null]],null]],[13],[1,\"\\n  \"],[10,\"td\"],[12],[1,[28,[35,3],[[28,[37,4],[\"monitoring.hedging-recalibration-table.direction-options.\",[30,3,[\"direction\"]]],null]],null]],[13],[1,\"\\n\"],[41,[28,[37,5],[[30,3,[\"showProfitAlert\"]],true],null],[[[1,\"    \"],[10,\"td\"],[12],[1,\"—\"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,6],null,[[\"@value\",\"@maximumFractionDigits\"],[[30,3,[\"forwardRateLimit\"]],6]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[10,\"td\"],[14,0,\"report-table__actions\"],[14,\"data-value\",\"\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"button\"],[16,\"disabled\",[30,1]],[24,4,\"button\"],[4,[38,7],[\"click\",[28,[37,8],[[30,4],[30,5],[30,3]],null]],null],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"monitoring.hedging-recalibration-table.add-transaction\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[18,6,null],[1,\"\\n\"],[13]],[\"@stale\",\"&attrs\",\"@dataset\",\"@openForwardTransactionModal\",\"@currency\",\"&default\"],false,[\"if\",\"table/date-header\",\"reports/currency-cell\",\"t\",\"concat\",\"eq\",\"reports/numeric-cell\",\"on\",\"fn\",\"yield\"]]",
    "moduleName": "fx/components/monitoring/hedging-recalibration-table-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});