define("fx/components/unselectable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span
    data-unselectable-text={{@text}}
    arial-label={{@text}}
    class="unselectable"
    ...attributes
  ></span>
  */
  {
    "id": "AQEsq6nd",
    "block": "[[[11,1],[16,\"data-unselectable-text\",[30,1]],[16,\"arial-label\",[30,1]],[24,0,\"unselectable\"],[17,2],[12],[13]],[\"@text\",\"&attrs\"],false,[]]",
    "moduleName": "fx/components/unselectable.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});