define("fx/components/hedging/overview-prices-full-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <th>
      {{@header}}
    </th>
  
    {{#each @types as |type|}}
      {{#let (get @dataset type.name) as |dataset|}}
        <Reports::CurrencyCell
          @value={{dataset.ratedValue}}
        />
  
        {{#if type.displayFlows}}
          <Reports::CurrencyCell
            @value={{dataset.financialFlows}}
          />
        {{/if}}
  
        <Reports::CurrencyCell
          @value={{dataset.value}}
        />
      {{/let}}
    {{/each}}
  
    {{yield}}
  </tr>
  */
  {
    "id": "CKHDEn1A",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[10,\"th\"],[12],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,3]],null]],null],null,[[[44,[[28,[37,3],[[30,5],[30,4,[\"name\"]]],null]],[[[1,\"      \"],[8,[39,4],null,[[\"@value\"],[[30,6,[\"ratedValue\"]]]],null],[1,\"\\n\\n\"],[41,[30,4,[\"displayFlows\"]],[[[1,\"        \"],[8,[39,4],null,[[\"@value\"],[[30,6,[\"financialFlows\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[8,[39,4],null,[[\"@value\"],[[30,6,[\"value\"]]]],null],[1,\"\\n\"]],[6]]]],[4]],null],[1,\"\\n  \"],[18,7,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@header\",\"@types\",\"type\",\"@dataset\",\"dataset\",\"&default\"],false,[\"each\",\"-track-array\",\"let\",\"get\",\"reports/currency-cell\",\"if\",\"yield\"]]",
    "moduleName": "fx/components/hedging/overview-prices-full-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});