define("fx/templates/admin/users/invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ONji4R31",
    "block": "[[[10,\"main\"],[14,0,\"no-bg\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"content--x-small\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[12],[1,[28,[35,1],[\"admin.users.invite.title\"],null]],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@errors\",\"@invite\",\"@onSubmit\"],[[30,0,[\"errors\"]],[30,0,[\"invite\"]],[30,0,[\"createInvite\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,2],[12],[1,[28,[35,1],[\"admin.users.invite.intro\"],null]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"content\",\"t\",\"invite-form\"]]",
    "moduleName": "fx/templates/admin/users/invite.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});