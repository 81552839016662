define("fx/components/reports/product-prices-in-primary-currency-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <th>
      {{@header}}
    </th>
  
    {{#each @dataset as |value|}}
      <Reports::CurrencyCell
        @minimumFractionDigits={{2}}
        @maximumFractionDigits={{2}}
        @value={{value.ratedPrice}}
      />
    {{/each}}
  
    {{yield}}
  </tr>
  */
  {
    "id": "pkSAjwks",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[10,\"th\"],[12],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,3]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@minimumFractionDigits\",\"@maximumFractionDigits\",\"@value\"],[2,2,[30,4,[\"ratedPrice\"]]]],null],[1,\"\\n\"]],[4]],null],[1,\"\\n  \"],[18,5,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@header\",\"@dataset\",\"value\",\"&default\"],false,[\"each\",\"-track-array\",\"reports/currency-cell\",\"yield\"]]",
    "moduleName": "fx/components/reports/product-prices-in-primary-currency-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});