define("fx/components/hedging/requisite-coverage-total-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <th colspan="2">
      {{@header}}
    </th>
  
    {{#each @dataset.hedgingMargins as |hedgingMargin|}}
      <Hedging::RequisiteCoverageHedgingMargin
        @hedgingMargin={{hedgingMargin}}
        @field={{@type}}
        @hideRatio={{true}}
        @mergeCells={{true}}
      />
    {{/each}}
  
    <td></td>
  
    {{yield}}
  </tr>
  */
  {
    "id": "rriCauET",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[10,\"th\"],[14,\"colspan\",\"2\"],[12],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,3,[\"hedgingMargins\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@hedgingMargin\",\"@field\",\"@hideRatio\",\"@mergeCells\"],[[30,4],[30,5],true,true]],null],[1,\"\\n\"]],[4]],null],[1,\"\\n  \"],[10,\"td\"],[12],[13],[1,\"\\n\\n  \"],[18,6,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@header\",\"@dataset\",\"hedgingMargin\",\"@type\",\"&default\"],false,[\"each\",\"-track-array\",\"hedging/requisite-coverage-hedging-margin\",\"yield\"]]",
    "moduleName": "fx/components/hedging/requisite-coverage-total-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});