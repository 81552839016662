define("fx/components/reports/financial-flows-net-flows-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <Table::DateHeader
      @date={{@month}}
    />
  
    {{#each @dataset as |dataset|}}
      <Reports::CurrencyCell
        @value={{dataset.value}}
      />
    {{/each}}
  
    {{yield}}
  </tr>
  */
  {
    "id": "DYhjFDL+",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@date\"],[[30,2]]],null],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,3]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@value\"],[[30,4,[\"value\"]]]],null],[1,\"\\n\"]],[4]],null],[1,\"\\n  \"],[18,5,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@month\",\"@dataset\",\"dataset\",\"&default\"],false,[\"table/date-header\",\"each\",\"-track-array\",\"reports/currency-cell\",\"yield\"]]",
    "moduleName": "fx/components/reports/financial-flows-net-flows-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});