define("fx/templates/budgets/budget/budget-periods/budget-period/monitoring/results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HvgbbO69",
    "block": "[[[10,\"h1\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"monitoring.results.title\"],null]],[1,\"\\n  \"],[8,[39,1],null,[[\"@budgetPeriod\",\"@analysis\"],[[30,0,[\"budgetPeriod\"]],[30,0,[\"analysis\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,2],null,[[\"@refetchQueries\"],[[28,[37,3],[\"RisksResultsMonitoring\"],null]]],null],[1,\"\\n\\n\"],[10,\"h2\"],[12],[1,\"\\n  \"],[1,[28,[35,4],[[30,0,[\"model\",\"budgetData\",\"date\"]]],[[\"month\",\"year\",\"day\"],[\"long\",\"numeric\",\"numeric\"]]]],[1,\"\\n\\n  \"],[10,\"small\"],[12],[1,\"\\n    (\\n      \"],[1,[28,[35,0],[\"monitoring.results.remaining-days\"],[[\"days\"],[[30,0,[\"model\",\"budgetData\",\"remainingDays\"]]]]]],[1,\"\\n    )\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@budgetData\"],[[30,0,[\"model\",\"budgetData\"]]]],null],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@unrealizedAnticipatedResults\",\"@netRiskBySalesMarket\"],[[30,0,[\"model\",\"unrealizedAnticipatedResults\"]],[30,0,[\"model\",\"netRiskBySalesMarket\"]]]],null],[1,\"\\n\\n\"],[8,[39,7],null,[[\"@grossProfitMargin\"],[[30,0,[\"model\",\"grossProfitMargin\"]]]],null],[1,\"\\n\\n\"],[8,[39,8],null,[[\"@dailyRateProfits\"],[[30,0,[\"model\",\"dailyRateProfits\"]]]],null],[1,\"\\n\\n\"],[8,[39,9],null,null,null],[1,\"\\n\"]],[],false,[\"t\",\"monitoring/heading\",\"monitoring/currency-rate-override-table\",\"array\",\"format-date\",\"monitoring/budget-data-table\",\"monitoring/unrealized-anticipated-results-table\",\"monitoring/gross-profit-margin-table\",\"monitoring/daily-rate-profits-table\",\"flow-bar\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/monitoring/results.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});