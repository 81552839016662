define("fx/components/dropdown/menu-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="dropdown__menu-header">
    {{yield}}
  </div>
  <div class="dropdown__separator" role="separator"></div>
  */
  {
    "id": "t1PSKUyC",
    "block": "[[[10,0],[14,0,\"dropdown__menu-header\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"dropdown__separator\"],[14,\"role\",\"separator\"],[12],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "fx/components/dropdown/menu-header.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});