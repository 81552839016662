define("fx/templates/budgets/budget/budget-periods/budget-period/hedging/analysis/used-ratios", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V8aYQtha",
    "block": "[[[10,\"h1\"],[14,1,\"used-ratios-title\"],[12],[1,[28,[35,0],[\"hedging.analysis.used-ratios.title\"],null]],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"hedging.analysis.used-ratios.instructions\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,1],[[24,0,\"report-table--left-headers\"],[24,\"aria-labelledby\",\"used-ratios-title\"]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Caption\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"Content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[12],[13],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"currencies\"]]],null]],null],null,[[[1,\"          \"],[10,\"th\"],[12],[1,[30,2,[\"isoCode\"]]],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"model\",\"datasets\"]]],null]],null],null,[[[1,\"        \"],[8,[39,4],null,[[\"@dataset\",\"@type\"],[[30,3,[\"currencies\"]],[30,3,[\"type\"]]]],null],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[8,[39,5],null,null,null],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@target\"],[\"sidebar\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,8],null,[[\"@analysis\",\"@refetchQueries\"],[[30,0,[\"analysis\"]],[28,[37,9],[\"RatiosUsed\"],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"table\",\"currency\",\"dataset\"],false,[\"t\",\"report-table\",\"each\",\"-track-array\",\"hedging/used-ratios-row\",\"flow-bar\",\"portal\",\"sidebar\",\"hedging/analysis-updater\",\"array\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/hedging/analysis/used-ratios.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});